<template>
    <div>
        <form>
            <div class="form-group row">                
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-4 ">
                    <label><strong>Payment Method </strong> <span class="required-indc">*</span></label>
                    <label class="custom-radio">EFT/ACH
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label>
                </div>                
            </div>

            <h3>If ACH, please complete the following: <span class="required-indc">*</span></h3>

            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Routing Number</label>                
                    <input type="text" class="reg-field" placeholder="eg: 123456789">
                    <span class="short-msg">What is Routing Number? <a href="#">Click Here</a></span>        
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Bank Name</label>                
                    <input type="text" class="reg-field" placeholder="eg: First Fed">
                </div>
            </div>

            <div class="form-group">
                <div class="mb-4 ">
                    <label>Account Number</label>                
                    <input type="text" class="reg-field" placeholder="eg: 456789">
                </div>
            </div>

            <div class="form-group">
                <div class="mb-4 ">
                    <label>Name on Bank Account</label>                
                    <input type="text" class="reg-field" placeholder="eg: Company 123">
                </div>
            </div>
            
        </form>
        <div class="action-btn split-action mt-5">
            <router-link to="/registration/billing-info" class="button-black">Back</router-link>
            <div class="action-next">
                <router-link to="/registration/group-contribution" class="button-blue">Next</router-link>
                <router-link to="/registration/group-contribution" class="button-green">Save & Continue Late</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PaymentForm'
}
</script>